import { invokeApi } from "../../bl_libs/invokeApi";

export const training_lessons_add_by_admin_api = async () => {
  const requestObj = {
    path: `api/training_lesson/training_lessons_add_by_admin`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const training_lesson_list_api = async () => {
  const requestObj = {
    path: `api/training_lesson/training_lessons_for_assiciate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_training_lesson_api = async (data) => {
  const requestObj = {
    path: `api/training_lesson`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_training_lesson_api = async (data, lesson_id) => {
  const requestObj = {
    path: `api/training_lesson/${lesson_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_training_lesson_api = async (lesson_id) => {
  const requestObj = {
    path: `api/training_lesson/${lesson_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

import ReactEditor from "react-text-editor-kit";
import { upload_general_image_api } from "../../DAL/general_routes/general_routes";
import { s3baseUrl } from "../../config/config";

function GeneralCkeditor({ inputs, setInputs, name }) {
  const image_handler = async (e) => {
    let formData = new FormData();
    formData.append("image", e.image);
    const result = await upload_general_image_api(formData);
    if (result.code === 200) {
      return s3baseUrl + result.image_path;
    } else {
      return "";
    }
  };

  const handleChange = (value) => {
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="App">
      <ReactEditor
        value={String(inputs[name])}
        onChange={handleChange}
        placeholder="Write your text here"
        image_handler={image_handler}
      />
    </div>
  );
}

export default GeneralCkeditor;

import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function ReportsFilter(props) {
  const { handleSubmit, filterData, handleChange, handleClearFilter } = props;
  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="form-control mt-4"
                placeholder="Search by Student Name"
                variant="outlined"
                name="part_mumber"
                value={filterData.search_text}
                onChange={(e) => handleChange("search_text", e.target.value)}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Lesson Book
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Lesson Book"
                  name="lesson_book"
                  className="mui-select"
                  value={filterData.lesson_book}
                  onChange={(e) => {
                    handleChange("lesson_book", e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="quran">Holy Quran</MenuItem>
                  <MenuItem value="quaida">Noorani Quaida</MenuItem>
                  <MenuItem value="madani_quaida">Madani Quaida</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="DD/MM/YYYY"
                  className="mt-4 form-control mui-date-picker"
                  value={filterData.start_date}
                  maxDate={new Date()}
                  onChange={(value) => handleChange("start_date", value.$d)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="DD/MM/YYYY"
                  className="mt-4 form-control mui-date-picker"
                  value={filterData.end_date}
                  maxDate={new Date()}
                  onChange={(value) => handleChange("end_date", value.$d)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="add--button d-flex justify-content-between mt-3">
            <button onClick={handleClearFilter}>
              Clear&nbsp;&nbsp;
              <ClearAllIcon />
            </button>
            <button>
              Filter&nbsp;&nbsp;
              <FilterAltIcon />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { CircularProgress, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { upload_general_image_api } from "../../DAL/general_routes/general_routes";
import { update_settings_api } from "../../DAL/Users/Users";
import { useContentSetting } from "../../Hooks/ContentSetting";
import ChooseImage from "../../components/GeneralComponents/ChooseImage";

export default function StudentPortalSetting() {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, setUserInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [state, setState] = useState({
    brand_name: "",
    meta_title: "",
    meta_decription: "",
    brand_logo: "",
    share_icon: "",
    favicon: "",
  });

  const handleUpload = async (event) => {
    const { name, files } = event.target;
    if (files[0]) {
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await upload_general_image_api(formData);
      if (result.code === 200) {
        let image_path = result.image_path;
        setState((old) => ({ ...old, [name]: image_path }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      student_portal_setting: state,
    };

    const result = await update_settings_api(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setUserInfo((old) => ({ ...old, ...formData }));
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event, setting) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.student_portal_setting) {
        setState(userInfo.student_portal_setting);
      }
      setIsLoadingPage(false);
    }
  }, []);

  if (isLoadingPage) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12">
              <h2 className="mt-4 mb-0">General Setting</h2>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="brand_name"
                label="Brand Name"
                variant="outlined"
                name="brand_name"
                value={state.brand_name}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <ChooseImage
                image_path={s3baseUrl + state.brand_logo}
                handleUpload={handleUpload}
                name="brand_logo"
                label="Brand Logo"
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="meta_title"
                label="Meta Title"
                variant="outlined"
                name="meta_title"
                value={state.meta_title}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <ChooseImage
                image_path={s3baseUrl + state.share_icon}
                handleUpload={handleUpload}
                name="share_icon"
                label="Share Icon"
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <ChooseImage
                image_path={s3baseUrl + state.favicon}
                handleUpload={handleUpload}
                name="favicon"
                label="Favicon"
              />
            </div>
            <div className="col-12">
              <TextField
                multiline
                rows={4}
                className="mt-4 form-control"
                id="meta_decription"
                label="Meta Decription"
                variant="outlined"
                name="meta_decription"
                value={state.meta_decription}
                required={true}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

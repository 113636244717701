import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  add_leaves_request_api,
  update_leaves_request_api,
} from "../../DAL/LeaveRequests/LeaveRequests";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { students_list_api } from "../../DAL/Students/Students";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";

export default function AddOrUpdateLeave({
  onCloseDrawer,
  setLeavesList,
  formType,
  selectedObject,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentsList, setStudentsList] = useState([]);

  const [state, setState] = useState({
    description: "",
    title: "",
    start_date: new Date(),
    end_date: new Date(),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let formData = {
      description: state.description,
      title: state.title,
      start_date: moment(state.start_date).format("YYYY-MM-DD"),
      end_date: moment(state.end_date).format("YYYY-MM-DD"),
      student: {
        _id: selectedStudent._id,
        name: selectedStudent.name,
      },
    };

    const result =
      formType === "ADD"
        ? await add_leaves_request_api(formData)
        : await update_leaves_request_api(formData, state._id);
    if (result.code === 200) {
      let leave_request = result.leave_request;
      leave_request.start_date = moment(state.start_date).format("DD-MM-YYYY");
      leave_request.end_date = moment(state.end_date).format("DD-MM-YYYY");
      leave_request.student_name = selectedStudent.name;
      setLeavesList((old) => [leave_request, ...old]);
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_students_list = async () => {
    const result = await students_list_api();
    if (result.code === 200) {
      setStudentsList(result.students_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  const handleChangeDate = (name, value) => {
    setState((old) => ({ ...old, [name]: value.$d }));
  };

  const handleChangeStudent = (value) => {
    setSelectedStudent(value);
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState(selectedObject);
      setSelectedStudent(selectedObject.student);
    }
    get_students_list();
  }, [formType, selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6 mt-4">
              <MUIAutocomplete
                inputLabel="Student"
                selectedOption={selectedStudent}
                setSelectedOption={handleChangeStudent}
                optionsList={studentsList}
                autoComplete="off"
                name="name"
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="title"
                label="Leave Title"
                variant="outlined"
                name="title"
                value={state.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="DD-MM-YYYY"
                  className="mt-4 form-control mui-date-picker"
                  value={state.start_date}
                  onChange={(e) => {
                    handleChangeDate("start_date", e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="DD-MM-YYYY"
                  className="mt-4 form-control mui-date-picker"
                  value={state.end_date}
                  onChange={(e) => {
                    handleChangeDate("end_date", e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12">
              <TextField
                multiline
                rows={5}
                className="mt-4 form-control"
                id="description"
                label="Leave Description"
                variant="outlined"
                name="description"
                value={state.description}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-12 add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

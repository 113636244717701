import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { CURRENCIES } from "../../utils/constant";
import { update_fees_setting_api } from "../../DAL/Clients/Clients";

export default function UpdateFeesSetting({
  onCloseDrawer,
  selectedObject,
  setClientsList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    amount: 0,
    currency: "usd",
    auto_manage: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      fees_setting: state,
    };

    const result = await update_fees_setting_api(formData, selectedObject._id);
    if (result.code === 200) {
      setClientsList((old) =>
        old.map((obj) => {
          if (obj._id === selectedObject._id) {
            obj.fees_setting = result.client.fees_setting;
          }
          return obj;
        })
      );
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    if (selectedObject.fees_setting) {
      setState(selectedObject.fees_setting);
    }
  }, [selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12">
              <FormControl variant="outlined" className="form-control mt-4">
                <InputLabel id="auto_manage">Auto Manage</InputLabel>
                <Select
                  labelId="auto_manage"
                  id="auto_manage"
                  label="Auto Manage"
                  name="auto_manage"
                  className="mui-select"
                  value={state.auto_manage}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="number"
                className="mt-4 form-control"
                id="amount"
                label="Amount"
                variant="outlined"
                name="amount"
                value={state.amount}
                onChange={handleChange}
                required
                inputProps={{ min: 1 }}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="currency">Currency</InputLabel>
                <Select
                  labelId="currency"
                  id="currency"
                  label="Currency"
                  name="currency"
                  className="mui-select"
                  value={state.currency}
                  onChange={handleChange}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <MenuItem value={currency.value}>
                        {currency.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

import React from "react";
import { currency_sign } from "../../utils/constant";

export default function StudentsTable(props) {
  const { students, currency, payment_charges, total_amount } = props;

  return (
    <table className="fees-info-table w-100">
      <thead>
        <tr>
          <td>Student Name</td>
          <td>Payable Fee</td>
        </tr>
      </thead>
      <tbody>
        {students.map((student) => {
          return (
            <tr key={student._id}>
              <td>{student.name}</td>
              <td>{currency_sign(currency) + student.fee_amount}</td>
            </tr>
          );
        })}

        <tr>
          <td className="total-fee-text">Sub Total</td>
          <td className="total-fee-text">
            {currency_sign(currency) + total_amount}
          </td>
        </tr>
        <tr>
          <td>Payment Charges (2.9%) </td>
          <td>{currency_sign(currency) + payment_charges.toFixed(2)}</td>
        </tr>
        <tr>
          <td className="total-fee-text">Total Fee</td>
          <td className="total-fee-text">
            {currency_sign(currency) +
              (total_amount + payment_charges).toFixed(2)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import { leaves_request_by_student_api } from "../../DAL/LeaveRequests/LeaveRequests";
import { Link, useParams } from "react-router-dom";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "title", label: "Leave Title" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "description", label: "Description" },
];

export default function LeaveRequestsByStudent() {
  const { user_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [leavesList, setLeavesList] = useState([]);
  const [studentInfo, setStudentInfo] = useState({});

  const get_leaves_list = async () => {
    setIsLoading(true);
    const result = await leaves_request_by_student_api(user_id);
    if (result.code === 200) {
      const data = result.leave_request.map((leave) => {
        let start_date = moment(leave.start_date).format("DD-MM-YYYY");
        let end_date = moment(leave.end_date).format("DD-MM-YYYY");
        return { ...leave, start_date, end_date };
      });
      setLeavesList(data);
      setStudentInfo(result.student);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_leaves_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-3">
                <div className="back-button-box">
                  <Link to={`/students`}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">
                  {studentInfo.name +
                    " (" +
                    studentInfo.roll_number_string +
                    ")"}
                </div>
              </div>
              <div className="mb-2 mui-table-heading">
                <h2>Leaves List</h2>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={leavesList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

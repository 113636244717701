import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { dashboard_stats_api } from "../../DAL/Dashboard/Dashboard";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "student_name", label: "Student Name" },
  { id: "title", label: "Leave Title" },
  { id: "description", label: "Description" },
];

export default function Dashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const [statsData, setStatsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [leavesList, setLeavesList] = useState([]);

  const get_dashboard_stats = async () => {
    const result = await dashboard_stats_api();
    if (result.code === 200) {
      setStatsData(result);
      let leave_request = [];
      if (result.leave_request.length > 0) {
        leave_request = result.leave_request.map((leave) => {
          let student_name = leave.student.name;
          let start_date = moment(leave.start_date).format("DD-MM-YYYY");
          let end_date = moment(leave.end_date).format("DD-MM-YYYY");
          return { ...leave, start_date, end_date, student_name };
        });
      }
      setLeavesList(leave_request);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_dashboard_stats();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-user-graduate"></i>
            </div>
            <h3>{statsData.total_students}</h3>
            <p>Students</p>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-user-graduate"></i>
            </div>
            <h3>{statsData.inactive_students}</h3>
            <p>Inactive Students</p>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-users"></i>
            </div>
            <h3>{statsData.total_groups}</h3>
            <p>Total Groups</p>
          </div>
        </div>{" "}
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-chalkboard-teacher"></i>
            </div>
            <h3>{statsData.total_teachers}</h3>
            <p>Total Teachers</p>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-bullhorn"></i>
            </div>
            <h3>{statsData.announcements}</h3>
            <p>Total Announcements</p>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fa-solid fa-book"></i>
            </div>
            <h3>{statsData.personal_note}</h3>
            <p>Personal Notes</p>
          </div>
        </div>
        {leavesList.length > 0 && (
          <div className="col-12 mt-3">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Today's Leaves</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={leavesList} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

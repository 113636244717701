import { invokeApi } from "../../bl_libs/invokeApi";

export const students_group_list_api = async () => {
  const requestObj = {
    path: `api/students_group/groups_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_student_group_api = async (data) => {
  const requestObj = {
    path: `api/students_group`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_fees_setting_api = async (data, group_id) => {
  const requestObj = {
    path: `api/students_group/update_fees_setting/${group_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_students_group_api = async (data, group_id) => {
  const requestObj = {
    path: `api/students_group/${group_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_students_group_api = async (group_id) => {
  const requestObj = {
    path: `api/students_group/${group_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import {
  add_student_api,
  update_student_api,
} from "../../DAL/Students/Students";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContentSetting } from "../../Hooks/ContentSetting";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { teachers_and_clients_api } from "../../DAL/Users/Users";

var moment = require("moment");

export default function AddOrUpdateStudent({
  onCloseDrawer,
  setStudentsList,
  formType,
  selectedObject,
  studentsList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useContentSetting();
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [birthDate, setbirthDate] = useState(new Date());
  const [teachersList, setTeachersList] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [state, setState] = useState({
    name: "",
    password: "",
    gender: "male",
    lesson_book: "quran",
    status: true,
    fee_amount: 0,
  });

  const handleChangeDate = (newValue) => {
    setbirthDate(newValue.$d);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const get_teachers_and_clients = async () => {
    const result = await teachers_and_clients_api();
    if (result.code === 200) {
      setTeachersList(result.teachers);
      setClientsList(result.clients);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let associate_object = {
      _id: userInfo._id,
      name: userInfo.name,
    };

    let teachers_array = [];
    if (selectedTeachers.length > 0) {
      selectedTeachers.map((teacher) => {
        teachers_array.push({
          _id: teacher._id,
          name: teacher.name,
        });
      });
    }

    let client_object = {
      _id: selectedClient._id,
      name: selectedClient.name,
      email: selectedClient.email,
    };

    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("status", state.status);
    formData.append("gender", state.gender);
    formData.append("fee_amount", state.fee_amount);
    formData.append("lesson_book", state.lesson_book);
    formData.append("birth_date", moment(birthDate).format("YYYY-MM-DD"));
    formData.append("associate", JSON.stringify(associate_object));
    formData.append("teachers", JSON.stringify(teachers_array));
    formData.append("client", JSON.stringify(client_object));
    if (image) {
      formData.append("profile_image", image);
    }
    if (formType === "ADD") {
      formData.append("password", state.password);
    }
    const result =
      formType === "ADD"
        ? await add_student_api(formData)
        : await update_student_api(formData, state._id);
    if (result.code === 200) {
      let new_student = result.student;
      let guardian_name = "";
      if (new_student.client) {
        guardian_name =
          new_student.client.name + "(" + new_student.client.email + ")";
      }

      new_student.created_at = moment(new_student.createdAt).format(
        "DD-MM-YYYY hh:mm A"
      );
      new_student.table_avatar = {
        src: s3baseUrl + new_student.profile_image,
        alt: new_student.name,
      };
      new_student.guardian_name = guardian_name;

      if (formType === "ADD") {
        setStudentsList((studentsList) => [new_student, ...studentsList]);
      } else {
        const newState = studentsList.map((obj) => {
          if (obj._id === state._id) {
            return new_student;
          }
          return obj;
        });
        setStudentsList(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    get_teachers_and_clients();
    if (formType === "EDIT") {
      setState(selectedObject);
      setbirthDate(
        moment(selectedObject.birth_date, "YYYY-MM-DD").format("MM-DD-YYYY")
      );
      setSelectedTeachers(selectedObject.teachers);
      setSelectedClient(selectedObject.client);
      if (selectedObject.profile_image) {
        setPreviews(s3baseUrl + selectedObject.profile_image);
      }
    }
  }, [formType]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="name"
                label="Name"
                variant="outlined"
                name="name"
                value={state.name}
                required={true}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date of Birth"
                  inputFormat="DD/MM/YYYY"
                  className="mt-4 form-control mui-date-picker"
                  value={birthDate}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Gender
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Gender"
                  name="gender"
                  className="mui-select"
                  value={state.gender}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Lesson Book
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Lesson Book"
                  name="lesson_book"
                  className="mui-select"
                  value={state.lesson_book}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value="quran">Holy Quran</MenuItem>
                  <MenuItem value="quaida">Noorani Quaida</MenuItem>
                  <MenuItem value="madani_quaida">Madani Quaida</MenuItem>
                </Select>
              </FormControl>
            </div>
            {formType === "ADD" && (
              <div className="col-12 col-md-6">
                <TextField
                  className="mt-4 form-control"
                  type="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  value={state.password}
                  required={true}
                  onChange={(e) => handleChange(e)}
                  autoComplete="new-password"
                />
              </div>
            )}
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                type="number"
                id="fee_amount"
                label="Fee Amount"
                variant="outlined"
                name="fee_amount"
                value={state.fee_amount}
                required={true}
                onChange={handleChange}
                autoComplete="new-password"
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="status">Status</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <MUIAutocomplete
                inputLabel="Guardian"
                selectedOption={selectedClient}
                setSelectedOption={setSelectedClient}
                optionsList={clientsList}
                autoComplete="new-password-2"
                name="name"
                required={true}
              />
            </div>
            {userInfo.has_school && (
              <div className={`col-12 mt-4`}>
                <MUIAutocomplete
                  inputLabel="Teachers"
                  selectedOption={selectedTeachers}
                  setSelectedOption={setSelectedTeachers}
                  optionsList={teachersList}
                  autoComplete="new-password"
                  multiple={true}
                  name="name"
                />
              </div>
            )}
            <div className="col-md-12 mt-3 d-flex">
              {previews && (
                <span className="upload-file-preview mt-3">
                  <span onClick={handleRemove}>x</span>
                  <img src={previews} alt="Preview" />
                </span>
              )}
              {previews === "" && (
                <span className="upload-button mt-2">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    name="affirmationImage"
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file">
                    <CloudUploadIcon />
                  </label>
                </span>
              )}
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

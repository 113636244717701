import React, { useState } from "react";
import CustomDrawer from "../GeneralComponents/CustomDrawer";
import AddOrUpdateReport from "./AddOrUpdateReport";
import { useContentSetting } from "../../Hooks/ContentSetting";

export default function AddReportHeader() {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { reportsList, setReportsList } = useContentSetting();
  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  return (
    <>
      <div className="add--button pe-5 mt-2 pt-1">
        <button onClick={handleOpenDrawer}>Add Report</button>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`Add Report`}
        componentToPassDown={
          <AddOrUpdateReport
            formType={"ADD"}
            onCloseDrawer={handleCloseDrawer}
            reportsList={[reportsList]}
            setReportsList={setReportsList}
            selectedObject={{}}
          />
        }
      />
    </>
  );
}

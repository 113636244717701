import React from "react";
import ParticipantView from "./ParticipantView";
import { useState } from "react";
import { useClassRoom } from "../../../Hooks/ClassRoom";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { logo } from "../../../assets";
import { useContentSetting } from "../../../Hooks/ContentSetting";

export default function MeetingView(props) {
  const {
    isIncomingCall,
    isCallAccepted,
    setIsIncomingCall,
    callUser,
    startCallTune,
    studentStream,
    lastCallID,
    myStream,
    micOn,
    setMicOn,
    toggleScreenShare,
    isSharedScreen,
  } = useClassRoom();

  const { userInfo, socket } = useContentSetting();
  const [isPlaying, setIsPlaying] = useState(true);
  const { setBoxType, boxType } = props;

  const acceptCall = () => {
    setIsIncomingCall(false);
    socket.emit("call:accepted", {
      to: callUser,
      from: userInfo._id,
    });
  };

  const handletoggleMic = () => {
    const audioTracks = myStream.getAudioTracks();
    audioTracks.forEach((track) => (track.enabled = !micOn));
    socket.emit("changed_media", {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
      media_type: "is_muted",
      media_status: micOn,
    });
    setMicOn(!micOn);
  };

  const handleSocketEvent = () => {
    let event = "call:canceled";
    if (isIncomingCall) {
      event = "call:declined";
    } else if (isCallAccepted) {
      event = "call:ended";
    }
    socket.emit(event, {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    });
  };

  const handletoggleAudio = () => {
    if (!startCallTune.paused) {
      setIsPlaying(false);
      startCallTune.pause();
    } else {
      setIsPlaying(true);
      startCallTune.play();
    }
  };

  return (
    <>
      {lastCallID ? (
        <>
          {studentStream ? (
            <ParticipantView setBoxType={setBoxType} boxType={boxType} />
          ) : (
            <div className="image-box">
              <img src={logo} alt="" />
            </div>
          )}
        </>
      ) : (
        <div className="image-box">
          <img src={logo} alt="" />
        </div>
      )}
      {boxType !== "CIRCLE" && lastCallID && (
        <div className="icon-box">
          {isIncomingCall ? (
            <Tooltip title={`Join Class`}>
              <div className="success-call-box" onClick={() => acceptCall()}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`subway:call-1`}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={`${micOn ? "Mute" : "Unmute"}`}>
              <div className="success-call-box" onClick={handletoggleMic}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${micOn ? "bi:mic-fill" : "vaadin:mute"}`}
                />
              </div>
            </Tooltip>
          )}
          {!isCallAccepted ? (
            <Tooltip title={`${isPlaying ? "Off" : "On"}`}>
              <div className="success-call-box" onClick={handletoggleAudio}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${
                    isPlaying ? "fa-solid:volume-up" : "fa-solid:volume-mute"
                  }`}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              title={`${isSharedScreen ? "Stop Sharing" : "Share Screen"}`}
            >
              <div
                className="success-call-box"
                onClick={() => toggleScreenShare(!isSharedScreen)}
              >
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${
                    isSharedScreen
                      ? "fluent:share-screen-stop-28-filled"
                      : "fluent:share-screen-start-28-filled"
                  }`}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip
            title={`${
              isIncomingCall
                ? "Decline Call"
                : isCallAccepted
                ? "Drop Call"
                : "Cancel Call"
            }`}
            onClick={handleSocketEvent}
          >
            <div className="success-call-box error">
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon="subway:call-3"
              />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}

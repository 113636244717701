import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { s3baseUrl } from "../../config/config";
import { Icon } from "@iconify/react";
import { useClassRoom } from "../../Hooks/ClassRoom";

let CALLING = ["calling", "ringing", "started"];

export default function UserCard(props) {
  const { selectedConversation, messagesList, isLoadingMessages } = props;
  const { handleStartCall, callUser } = useClassRoom();
  let conversations_user = selectedConversation?.members.find(
    (user) => user.user_type === "student"
  );

  let last_message = null;

  if (messagesList.length > 0) {
    last_message = messagesList[messagesList.length - 1];
  }

  return (
    <div className="user-card" id="selected-user-card">
      {selectedConversation && (
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="position-relative">
              <Avatar
                sx={{ width: 40, height: 40 }}
                src={s3baseUrl + conversations_user.profile_image}
                className="conversation-avatar"
              >
                {conversations_user.name[0]}
              </Avatar>
              <div className="styled-badge">
                <div
                  className={`user-badge ${
                    conversations_user.is_online ? "user-online" : ""
                  }`}
                ></div>
              </div>
            </div>
            <div className="user-name mt-2 ms-2">
              <h6>{conversations_user?.name}</h6>
            </div>
          </div>
          {(!last_message ||
            last_message.message_type !== "call" ||
            !CALLING.includes(last_message.call_status)) &&
            !isLoadingMessages &&
            !callUser && (
              <div className="call-buttons me-2 mt-1">
                <Tooltip title="Start Call">
                  <div
                    className="start-call-box audio-call"
                    onClick={() => {
                      handleStartCall(
                        selectedConversation._id,
                        conversations_user
                      );
                    }}
                  >
                    <Icon
                      fontSize="18"
                      className="start-call-icon"
                      icon="ion:call-outline"
                    />
                  </div>
                </Tooltip>
              </div>
            )}
        </div>
      )}
    </div>
  );
}

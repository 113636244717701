import { List } from "@mui/material";
import React, { useState } from "react";
import NavSectionItem from "./NavSectionItem";
import SearchIcon from "@mui/icons-material/Search";

export default function NavSection({ navConfig }) {
  const [serachText, setSerachText] = useState("");

  const handleChange = (event) => {
    setSerachText(event.target.value);
  };

  const getNavItemsList = (array, query) => {
    if (query) {
      const _nav_list = array
        .map((data) => {
          const is_main =
            data.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
          const match_child = data.child_options?.filter(
            (item) =>
              item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );

          if (is_main) {
            return data;
          } else if (match_child?.length > 0) {
            return {
              ...data,
              child_options: match_child,
            };
          }
          return null;
        })
        .filter(Boolean);
      return _nav_list;
    }

    return array;
  };

  return (
    <div>
      <div className="filter-search-chats p-2">
        <div className="search-icon">
          <SearchIcon />
        </div>
        <input
          type="text"
          placeholder="Search Menu"
          value={serachText}
          onChange={handleChange}
        />
      </div>
      <List className="py-0">
        {getNavItemsList(navConfig, serachText).map((item, index) => {
          return (
            <NavSectionItem data={item} key={index} serachText={serachText} />
          );
        })}
      </List>
    </div>
  );
}

import { Avatar, Divider, Tooltip } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { s3baseUrl } from "../../config/config";
import {
  date_to_display,
  get_short_string,
  markdown_conversion,
} from "../../utils/constant";
import { useContentSetting } from "../../Hooks/ContentSetting";
import PanoramaIcon from "@mui/icons-material/Panorama";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import FilterListIcon from "@mui/icons-material/FilterList";
import RecordNotFound from "../GeneralComponents/RecordNotFound";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export default function Conversations({
  conversationsList,
  handleSelected,
  selectedConversation,
  get_conversations_list,
}) {
  const [serachText, setSerachText] = useState("");
  const [isUnread, setIsUnread] = useState(false);
  const { userInfo } = useContentSetting();

  const handleChange = (event) => {
    setSerachText(event.target.value);
  };

  const handleUnread = () => {
    get_conversations_list(isUnread ? "all" : "unread");
    setIsUnread(!isUnread);
  };

  const get_conversation = (query) => {
    let dataToFilter = [...conversationsList];
    if (query) {
      const filteredConversations = dataToFilter.filter((conversation) =>
        conversation.members.some(
          (member) =>
            member.user_type === "student" &&
            member.name.toLowerCase().includes(query.toLowerCase())
        )
      );
      return filteredConversations;
    } else {
      return dataToFilter;
    }
  };

  return (
    <div className="chatting-sidebar">
      <div className="search-bar mb-2">
        <div className="filter-search-chats">
          <div className="search-icon">
            <SearchIcon />
          </div>
          <input type="text" value={serachText} onChange={handleChange} />
          <div
            className={`filter-icon ${isUnread ? "unread-chats" : ""}`}
            onClick={handleUnread}
          >
            <Tooltip title={isUnread ? "All" : "Unread"}>
              <FilterListIcon />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="chatting-users-list">
        {get_conversation(serachText).length > 0 ? (
          get_conversation(serachText).map((item, index) => {
            let conversations_user = item.members.find(
              (user) => user.user_type === "student"
            );

            let unread_message_count = item.members.find(
              (user) => user._id === userInfo._id
            ).unread_message_count;

            return (
              <>
                <div
                  className={`d-flex justify-content-between chatting-user p-2 ${
                    item._id === selectedConversation?._id ? "menuActive" : ""
                  }`}
                  onClick={() => {
                    handleSelected(item);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className="position-relative">
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        src={s3baseUrl + conversations_user.profile_image}
                        className="conversation-avatar"
                      >
                        {conversations_user.name[0]}
                      </Avatar>

                      <div className="styled-badge">
                        <div
                          className={`user-badge ${
                            conversations_user.is_online ? "user-online" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                    <div className="user-name">
                      <h5>{conversations_user.name}</h5>
                      {item.last_message && (
                        <p>
                          {item.last_message?.message ? (
                            get_short_string(
                              markdown_conversion(item.last_message?.message),
                              25
                            )
                          ) : item.last_message?.message_type === "call" ? (
                            <div className="last-message-box">
                              <CallEndIcon /> Call
                            </div>
                          ) : item.last_message?.images?.length > 0 ? (
                            <div className="last-message-box">
                              <PanoramaIcon /> Photo
                            </div>
                          ) : (
                            item.last_message?.recording_url && (
                              <div className="last-message-box">
                                <MicIcon /> Voice Message
                              </div>
                            )
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  {item.last_message && (
                    <div>
                      <div className="message-date mt-0 pt-0">
                        {item.last_message.message_type !== "call" &&
                          conversations_user._id !==
                            item.last_message?.sender_id && (
                            <span
                              className={`me-1 ${
                                item.last_message.message_status === "seen"
                                  ? "message-status-seen"
                                  : ""
                              }`}
                            >
                              {item.last_message.message_status === "sent" ? (
                                <DoneIcon />
                              ) : (
                                <DoneAllIcon />
                              )}
                            </span>
                          )}
                        <span>
                          {date_to_display(item.last_message?.createdAt)}
                        </span>
                      </div>
                      {unread_message_count > 0 && (
                        <div className="text-end">
                          <span className="unread_message_count">
                            {unread_message_count}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <Divider className="sidebar-divider" />
              </>
            );
          })
        ) : (
          <RecordNotFound title="Chats Not Found" />
        )}
      </div>
    </div>
  );
}

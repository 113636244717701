import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";

export default function ComponentPopup({
  openPopup,
  componentToPassDown,
  setOpenPopup,
  title,
  paperClass,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openPopup}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className="mui-component-popup"
      PaperProps={{ className: paperClass || "" }}
    >
      <div className="d-flex justify-content-between">
        <h3 className="mui-component-popup-title">{title}</h3>
        <button className="drawer-cross-icon" onClick={handleClose}>
          <Icon icon={closeFill} width={20} height={20} />
        </button>
      </div>
      <div className="show-component-center">{componentToPassDown}</div>
    </Dialog>
  );
}

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import AddOrUpdateTrainingLesson from "../../components/TrainingLesson/AddOrUpdateTrainingLesson";
import {
  delete_training_lesson_api,
  training_lesson_list_api,
} from "../../DAL/TrainingLesson/TrainingLesson";

export default function TrainingLesson() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [trainingLesson, setTrainingLesson] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_training_lesson_list = async () => {
    const result = await training_lesson_list_api();
    if (result.code === 200) {
      let lessons = result.training_lessons.map((lesson) => {
        let menu_options = [];
        if (lesson.action_type === "associate_user") {
          menu_options = MENU_OPTIONS;
        }
        return { ...lesson, MENU_OPTIONS: menu_options };
      });
      setTrainingLesson(lessons);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "english_sentence", label: "English" },
    {
      id: "pronunciation",
      label: "Pronunciation",
      className: "nastaleeq text-end",
      label_class: "text-end",
    },
    {
      id: "urdu_sentence",
      label: "Urdu",
      className: "nastaleeq text-end",
      label_class: "text-end",
    },
    { id: "status", label: "Status", type: "row_status" },
    { id: "action", label: "Action", type: "action" },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_training_lesson_api(selectedObject._id);
    if (result.code === 200) {
      const data = trainingLesson.filter(
        (lesson) => lesson._id !== selectedObject._id
      );
      setTrainingLesson(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_training_lesson_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Training Lessons List</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button onClick={handleOpenDrawer}>Add Lesson</button>
                </div>
              </div>

              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={trainingLesson} />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Update"} Training Lesson`}
        componentToPassDown={
          <AddOrUpdateTrainingLesson
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            trainingLesson={trainingLesson}
            setTrainingLesson={setTrainingLesson}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this lesson?"}
        handleAgree={handleDelete}
      />
    </>
  );
}

import React from "react";
import { sad_emoji } from "../../assets";
import { useClassRoom } from "../../Hooks/ClassRoom";

export default function StudentOffline({ handleClose }) {
  const { selectedUser } = useClassRoom();

  return (
    <div className="student-offline-box">
      <div className="sad-emoji-box text-center">
        <img src={sad_emoji} alt="" srcset="" />
        <h4 className="mt-3">{`${selectedUser.name} is Currently Offline`}</h4>
      </div>
      <div className="add--button text-end mt-3">
        <button onClick={handleClose}>Ok</button>
      </div>
    </div>
  );
}

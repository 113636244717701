import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { students_list_api } from "../../DAL/Students/Students";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import {
  add_student_group_api,
  update_students_group_api,
} from "../../DAL/StudentsGroups/StudentsGroups";

export default function AddOrUpdateStudentsGroup({
  onCloseDrawer,
  setStudentsGroupList,
  formType,
  selectedObject,
  studentsGroupList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [studentsList, setStudentsList] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [state, setState] = useState({
    title: "",
    status: true,
  });

  const get_students_list = async () => {
    const result = await students_list_api();
    if (result.code === 200) {
      setStudentsList(result.students_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let students_array = [];
    if (selectedStudents.length > 0) {
      selectedStudents.map((student) => {
        students_array.push({
          _id: student._id,
          name: student.name,
        });
      });
    }

    const formData = {
      title: state.title,
      status: state.status,
      students: students_array,
    };

    const result =
      formType === "ADD"
        ? await add_student_group_api(formData)
        : await update_students_group_api(formData, state._id);
    if (result.code === 200) {
      if (formType === "ADD") {
        setStudentsGroupList((old) => [result.students_group, ...old]);
      } else {
        const newState = studentsGroupList.map((obj) => {
          if (obj._id === state._id) {
            return result.students_group;
          }
          return obj;
        });
        setStudentsGroupList(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState(selectedObject);
      setSelectedStudents(selectedObject.students);
    }
  }, [formType]);

  useEffect(() => {
    get_students_list();
  }, []);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="title"
                label="Title"
                variant="outlined"
                name="title"
                value={state.title}
                required={true}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mt-4">
              <MUIAutocomplete
                inputLabel="Students"
                selectedOption={selectedStudents}
                setSelectedOption={setSelectedStudents}
                optionsList={studentsList}
                autoComplete="off"
                multiple={true}
                name="name"
              />
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

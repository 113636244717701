import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../../config/config";
import { useParams } from "react-router-dom";
import {
  add_course_api,
  update_course_api,
} from "../../../DAL/WebPages/Course";
import GeneralCkeditor from "../../GeneralComponents/GeneralCkeditor";

export default function AddOrUpdateCourse({
  onCloseDrawer,
  setCourses,
  formType,
  selectedObject,
  courses,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const { page_slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [state, setState] = useState({
    title: "",
    description: "",
    detail_description: "",
    status: true,
  });

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("title", state.title);
    formData.append("status", state.status);
    formData.append("description", state.description);
    formData.append("detail_description", state.detail_description);
    formData.append("web_page", page_slug);
    if (image) {
      formData.append("image", image);
    }

    const result =
      formType === "ADD"
        ? await add_course_api(formData)
        : await update_course_api(formData, state._id);

    if (result.code === 200) {
      let course = result.course;
      course.table_avatar = {
        src: s3baseUrl + course.image,
        alt: course.title,
      };
      if (formType === "ADD") {
        setCourses((courses) => [course, ...courses]);
      } else {
        const newState = courses.map((obj) => {
          if (obj._id === state._id) {
            return result.course;
          }
          return obj;
        });
        setCourses(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState(selectedObject);
      if (selectedObject.image) {
        setPreviews(s3baseUrl + selectedObject.image);
      }
    }
  }, [formType]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="title"
                label="Title"
                variant="outlined"
                name="title"
                value={state.title}
                required={true}
                onChange={handleChange}
              />
            </div>

            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                className="mt-4 form-control"
                type="number"
                id="description"
                label="Description"
                variant="outlined"
                name="description"
                multiline
                rows={5}
                value={state.description}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <h6>Description *</h6>
              <GeneralCkeditor
                setInputs={setState}
                inputs={state}
                name="detail_description"
                editorHeight={320}
              />
            </div>
            <div className="col-md-12 mt-3 d-flex">
              {previews && (
                <span className="upload-file-preview mt-3">
                  <span onClick={handleRemove}>x</span>
                  <img src={previews} />
                </span>
              )}
              {previews === "" && (
                <span className="upload-button mt-2">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    name="affirmationImage"
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file">
                    <CloudUploadIcon />
                  </label>
                </span>
              )}
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  get_stripe_setting_api,
  update_stripe_setting_api,
} from "../../DAL/StripeSetting/StripeSetting";

const EMPTY_VALUES = {
  sandbox_publish_key: "",
  sandbox_secret_key: "",
  live_publish_key: "",
  live_secret_key: "",
  stripe_mode: "sandbox",
};

export default function StripeSetting() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [inputs, setInputs] = useState(EMPTY_VALUES);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      sandbox_publish_key: inputs.sandbox_publish_key,
      sandbox_secret_key: inputs.sandbox_secret_key,
      live_publish_key: inputs.live_publish_key,
      live_secret_key: inputs.live_secret_key,
      stripe_mode: inputs.stripe_mode,
    };
    const result = await update_stripe_setting_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const get_stripe_setting = async () => {
    const result = await get_stripe_setting_api();
    if (result.code === 200) {
      if (result.stripe_setting) {
        setInputs(result.stripe_setting);
      }
      setIsLoadingPage(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    get_stripe_setting();
  }, []);

  if (isLoadingPage) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12">
              <h2 className="mt-4 mb-0">Stripe Setting</h2>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Sandbox Publish Key"
                variant="outlined"
                fullWidth
                name="sandbox_publish_key"
                value={inputs.sandbox_publish_key}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Sandbox Secret Key "
                variant="outlined"
                fullWidth
                name="sandbox_secret_key"
                value={inputs.sandbox_secret_key}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Live Publish Key"
                variant="outlined"
                fullWidth
                name="live_publish_key"
                value={inputs.live_publish_key}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Live Secret Key"
                variant="outlined"
                fullWidth
                name="live_secret_key"
                value={inputs.live_secret_key}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <FormControl fullWidth className="form-control">
                <InputLabel id="demo-simple-select-label">
                  Stripe Mode
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="stripe_mode"
                  value={inputs.stripe_mode}
                  label="Stripe Mode"
                  onChange={handleChange}
                >
                  <MenuItem value="sandbox">SandBox</MenuItem>
                  <MenuItem value="live">Live</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

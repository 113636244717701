import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl, websiteBaseUrl } from "../../config/config";
import AddOrUpdateWebPage from "../../components/WebPages/AddOrUpdateWebPage";
import {
  delete_web_pages_api,
  import_template_data_api,
  web_pages_list_by_action_api,
} from "../../DAL/WebPages/web_pages";
import UpdatePageContent from "../../components/WebPages/UpdatePageContent";
import { useLocation, useNavigate } from "react-router-dom";

export default function WebPages({ is_template }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isUpdateContent, setIsUpdateContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [webPages, setWebPages] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleOpenUpdateContent = (row) => {
    setSelectedObject(row);
    setIsUpdateContent(true);
  };
  const handleCloseUpdateContent = () => {
    setSelectedObject({});
    setIsUpdateContent(false);
  };
  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_web_pages_list = async () => {
    setIsLoading(true);
    const result = await web_pages_list_by_action_api(is_template);
    if (result.code === 200) {
      const data = result.pages_list.map((web_page) => {
        let import_data = "";
        if (!web_page.is_data_imported) {
          import_data = "Import";
        }
        return {
          ...web_page,
          table_avatar: {
            src: s3baseUrl + web_page.brand_logo,
            alt: web_page.title,
          },
          import: import_data,
        };
      });
      setWebPages(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenImage = (row) => {
    window.open(s3baseUrl + row.brand_logo);
  };

  const handleImportData = async (data) => {
    const result = await import_template_data_api(data._id);
    if (result.code === 200) {
      setWebPages((old) => {
        return old.map((page) => {
          if (page._id === data._id) {
            return {
              ...page,
              page_content: result.web_page.page_content,
              is_data_imported: true,
            };
          }
          return page;
        });
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "table_avatar",
      label: "Brand Image",
      type: "thumbnail",
      className: "reduce-cell-padding cursor-pointer",
      handleClick: handleOpenImage,
    },
    { id: "status", label: "Status", type: "row_status" },
    {
      id: "import",
      label: "Import",
      renderData: (row) => {
        return (
          <div className="import_data primary-link">
            {row.is_data_imported ? (
              <a
                href={websiteBaseUrl + row.page_slug}
                rel="noreferrer"
                target="_blank"
              >
                Preview
              </a>
            ) : (
              <span
                onClick={() => {
                  handleImportData(row);
                }}
              >
                Import
              </span>
            )}
          </div>
        );
      },
    },
    { id: "action", label: "Action", type: "action" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleImageSlider = (value) => {
    navigate(`${pathname}/image-slider/${value.page_slug}`);
  };
  const handleCourses = (value) => {
    navigate(`${pathname}/courses/${value.page_slug}`);
  };
  const handleIslamicPillar = (value) => {
    navigate(`${pathname}/islamic-pillars/${value.page_slug}`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Update Page Content",
      icon: "akar-icons:edit",
      handleClick: handleOpenUpdateContent,
    },
    {
      label: "Image Slider",
      icon: "akar-icons:edit",
      handleClick: handleImageSlider,
    },
    {
      label: "Courses",
      icon: "akar-icons:edit",
      handleClick: handleCourses,
    },
    {
      label: "Islamic Pillars",
      icon: "akar-icons:edit",
      handleClick: handleIslamicPillar,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_web_pages_api(selectedObject._id);
    if (result.code === 200) {
      const data = webPages.filter(
        (web_page) => web_page._id !== selectedObject._id
      );
      setWebPages(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_web_pages_list();
  }, [is_template]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>{`${is_template ? "Template" : "Web"} Pages`}</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button onClick={handleOpenDrawer}>{`Add ${
                    is_template ? "Template" : "Page"
                  }`}</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={webPages}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Update"} Web Page`}
        componentToPassDown={
          <AddOrUpdateWebPage
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            webPages={webPages}
            setWebPages={setWebPages}
            selectedObject={selectedObject}
            is_template={is_template}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={isUpdateContent}
        onOpenDrawer={handleOpenUpdateContent}
        onCloseDrawer={handleCloseUpdateContent}
        pageTitle="Update Page Content"
        componentToPassDown={
          <UpdatePageContent
            onCloseDrawer={handleCloseUpdateContent}
            setWebPages={setWebPages}
            selectedObject={selectedObject}
            is_template={is_template}
          />
        }
      />
      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this page?"}
        handleAgree={handleDelete}
      />
    </>
  );
}

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { training_lessons_add_by_admin_api } from "../../DAL/TrainingLesson/TrainingLesson";

export default function AdminTrainingLesson() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [trainingLesson, setTrainingLesson] = useState([]);

  const get_training_lesson_list = async () => {
    const result = await training_lessons_add_by_admin_api();
    if (result.code === 200) {
      setTrainingLesson(result.training_lessons);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "english_sentence", label: "English" },
    {
      id: "pronunciation",
      label: "Pronunciation",
      className: "nastaleeq text-end",
      label_class: "text-end",
    },
    {
      id: "urdu_sentence",
      label: "Urdu",
      className: "nastaleeq text-end",
      label_class: "text-end",
    },
  ];

  useEffect(() => {
    get_training_lesson_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Training Lessons By Admin</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={trainingLesson} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

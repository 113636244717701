import { invokeApi } from "../../bl_libs/invokeApi";

export const fees_management_list_api = async (type) => {
  const requestObj = {
    path: `api/fees_management`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_fee_request_api = async (data) => {
  const requestObj = {
    path: `api/fees_management`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_fees_management_api = async (data, request_id) => {
  const requestObj = {
    path: `api/fees_management/${request_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_fees_request_api = async (request_id) => {
  const requestObj = {
    path: `api/fees_management/${request_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const fees_management_by_client_api = async (user_id) => {
  const requestObj = {
    path: `api/fees_management/fees_by_client/${user_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

import { invokeApi } from "../../bl_libs/invokeApi";

export const clients_list_api = async (role) => {
  const requestObj = {
    path: `api/clients`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const clients_list_with_students_api = async (role) => {
  const requestObj = {
    path: `api/clients/clients_list_with_students`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_client_api = async (data) => {
  const requestObj = {
    path: `api/clients`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const change_client_password_api = async (client_id, data) => {
  const requestObj = {
    path: `api/clients/change_password/${client_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_client_api = async (data, client_id) => {
  const requestObj = {
    path: `api/clients/${client_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_client_api = async (client_id) => {
  const requestObj = {
    path: `api/clients/${client_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const generate_client_token_api = async (student_id) => {
  const requestObj = {
    path: `api/clients/generate_token/${student_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_fees_setting_api = async (data, client_id) => {
  const requestObj = {
    path: `api/clients/update_fees_setting/${client_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import { styled } from "@mui/material/styles";
import { member_init_api } from "../../DAL/Login/Login";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { CircularProgress } from "@mui/material";
import { ClassRoom } from "../../Hooks/ClassRoom";
import { deviceDetect } from "react-device-detect";

// const drawerWidth = 260;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 65;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function DashboardLayout() {
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(260);
  const { setUserInfo, setUnreadMessageCount, setStartedMeeting } =
    useContentSetting();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handlePermanentDrawer = () => {
    if (drawerWidth) {
      setDrawerWidth(0);
    } else {
      setDrawerWidth(260);
    }
  };

  const get_member_init = async () => {
    const deviceInfo = deviceDetect();
    const postData = { device_info: deviceInfo };
    const result = await member_init_api(postData);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.associate));
      setUserInfo(result.associate);
      setUnreadMessageCount(result.unread_message_count);
      setStartedMeeting(result.started_meeting);
      setIsLoading(false);
    } else {
      localStorage.removeItem("user_data");
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
      return;
    }
    get_member_init();
  }, []);

  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <ClassRoom>
      <RootStyle>
        <AppHeader
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
          handlePermanentDrawer={handlePermanentDrawer}
        />
        <AppSidebar
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </ClassRoom>
  );
}

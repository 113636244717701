import { invokeApi } from "../../bl_libs/invokeApi";

export const update_user_profile = async (data) => {
  const requestObj = {
    path: `api/associates/update_profile`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const change_password_api = async (data) => {
  const requestObj = {
    path: `api/associates/change_my_password`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_settings_api = async (data) => {
  const requestObj = {
    path: `api/associates/update_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const teachers_and_clients_api = async () => {
  const requestObj = {
    path: `api/associates/teachers_and_clients`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

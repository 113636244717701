import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import {
  AnnouncementsList,
  Dashboard,
  HolyQuranIndexes,
  HolyQuranIndexMainPage,
  HolyQuranIndexPages,
  LessonBooks,
  Login,
  QuidaEnglishIndexMainPage,
  QuidaEnglishIndexPages,
  StudentsList,
  LoginByAdmin,
  PersonalNotes,
  AnnouncementDetail,
  Chatting,
  TeachersList,
  StudentsListByTeacher,
  StudentsGroups,
  AnnouncementsTemplates,
  AnnouncementsUsers,
  UserAnnouncementsList,
  WebPages,
  ImageSlider,
  IslamicPillar,
  CourseList,
  StudentPortalSetting,
  TeacherPortalSetting,
  TrainingLesson,
  AdminTrainingLesson,
  ToDoLists,
  LeaveRequests,
  LeaveRequestsByStudent,
  FeesManagement,
  FeesByClient,
  StudentReports,
  StripeSetting,
  ClientsList,
} from "./pages";

export default function Router() {
  const Authentication = () => {
    if (localStorage.getItem("token")) {
      return <Navigate to="/dashboard"> </Navigate>;
    }
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login"> </Navigate>;
    }
  };

  return (
    <Routes>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/lesson-books" element={<LessonBooks />} />
        <Route path="/lesson-books/quran-pak" element={<HolyQuranIndexes />} />
        <Route
          path="/lesson-books/quran-pak/:index"
          element={<HolyQuranIndexPages />}
        />
        <Route
          path="/lesson-books/quran-pak/:index/:page_index"
          element={<HolyQuranIndexMainPage />}
        />
        <Route
          path="/lesson-books/quida-english"
          element={<QuidaEnglishIndexPages />}
        />
        <Route
          path="/lesson-books/quida-english/:page_index"
          element={<QuidaEnglishIndexMainPage />}
        />
        <Route path="/students" element={<StudentsList />} />
        <Route path="/class-room" element={<Chatting />} />
        <Route path="/students-groups" element={<StudentsGroups />} />
        <Route path="/training-lesson" element={<TrainingLesson />} />
        <Route
          path="/admin-training-lesson"
          element={<AdminTrainingLesson />}
        />
        <Route path="/teachers" element={<TeachersList />} />
        <Route path="/guardians" element={<ClientsList />} />
        <Route
          path="/guardians/fees-management/:user_id"
          element={<FeesByClient />}
        />
        <Route
          path="/teachers/students-list/:teacher_id"
          element={<StudentsListByTeacher />}
        />
        <Route
          path="/students/leave-requests/:user_id"
          element={<LeaveRequestsByStudent />}
        />{" "}
        <Route path="/class-room/:conversation_id" element={<Chatting />} />
        <Route path="/student-reports" element={<StudentReports />} />
        <Route path="/to-do-lists" element={<ToDoLists />} />
        <Route path="/announcements" element={<AnnouncementsList />} />
        <Route path="/announcements-list" element={<UserAnnouncementsList />} />
        <Route
          path="/announcement-templates"
          element={<AnnouncementsTemplates />}
        />
        <Route
          path="/announcement-templates/detail/:announcement_id"
          element={<AnnouncementDetail />}
        />
        <Route
          path="/announcements-list/detail/:announcement_id"
          element={<AnnouncementDetail />}
        />
        <Route
          path="/announcements-list/users/:announcement_id"
          element={<AnnouncementsUsers />}
        />
        <Route
          path="/announcement-templates"
          element={<AnnouncementsTemplates />}
        />
        <Route
          path="/announcements/detail/:announcement_id"
          element={<AnnouncementDetail />}
        />
        <Route path="/personal-notes" element={<PersonalNotes />} />
        <Route path="/leave-requests" element={<LeaveRequests />} />
        <Route path="/fees-management" element={<FeesManagement />} />
        <Route path="/general-setting" element={<StudentPortalSetting />} />
        <Route path="/stripe-setting" element={<StripeSetting />} />
        <Route
          path="/teacher-portal-setting"
          element={<TeacherPortalSetting />}
        />
        <Route path="/web-pages" element={<WebPages is_template={false} />} />
        <Route
          path="/template-pages"
          element={<WebPages is_template={true} />}
        />
        <Route
          path="/web-pages/image-slider/:page_slug"
          element={<ImageSlider />}
        />
        <Route
          path="/template-pages/image-slider/:page_slug"
          element={<ImageSlider />}
        />
        <Route
          path="/web-pages/islamic-pillars/:page_slug"
          element={<IslamicPillar />}
        />
        <Route
          path="/template-pages/islamic-pillars/:page_slug"
          element={<IslamicPillar />}
        />
        <Route path="/web-pages/courses/:page_slug" element={<CourseList />} />
        <Route
          path="/template-pages/courses/:page_slug"
          element={<CourseList />}
        />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
}

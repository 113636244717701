import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import {
  FEES_STATUSES,
  MONTHS_ARRAY,
  show_proper_words,
} from "../../utils/constant";
import {
  add_fee_request_api,
  update_fees_management_api,
} from "../../DAL/FeesManagement/FeesManagement";
import { s3baseUrl } from "../../config/config";
import { clients_list_with_students_api } from "../../DAL/Clients/Clients";
import StudentsTable from "./StudentsTable";

let current_year = new Date().getFullYear();
let years = [current_year - 1, current_year, current_year - 1];

function getCurrentMonth() {
  const currentMonthIndex = new Date().getMonth();
  return MONTHS_ARRAY[currentMonthIndex - 1];
}
let previous_month = getCurrentMonth();

const EMPTY_VALUES = {
  amount: 0,
  currency: "usd",
  fee_status: "pending",
  month: previous_month.value,
  year: current_year,
  receipt_slip: "",
};

export default function AddOrUpdateFees({
  onCloseDrawer,
  setFeesList,
  formType,
  selectedObject,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [clientsList, setClientsList] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [state, setState] = useState(EMPTY_VALUES);

  let total_amount = 0;
  selectedStudents?.map((student) => {
    if (student.fee_amount) {
      total_amount += student.fee_amount;
    } else {
      total_amount += 0;
    }
  });

  const payment_charges = total_amount * 0.029;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUser) {
      enqueueSnackbar(`Please select Guardian`, { variant: "error" });
      return;
    }
    if (selectedStudents.length < 1) {
      enqueueSnackbar(`Please select at least One student`, {
        variant: "error",
      });
      return;
    }

    setIsLoading(true);
    let students = selectedStudents.map((student) => {
      return {
        _id: student._id,
        name: student.name,
        fee_amount: student.fee_amount,
      };
    });

    const formData = new FormData();
    formData.append("currency", state.currency);
    formData.append("fee_status", state.fee_status);
    formData.append("amount", total_amount);
    formData.append("month", state.month);
    formData.append("year", state.year);
    formData.append("payment_charges", payment_charges);
    formData.append("created_for", selectedUser?._id);
    formData.append("students", JSON.stringify(students));

    const result =
      formType === "ADD"
        ? await add_fee_request_api(formData)
        : await update_fees_management_api(formData, state._id);
    if (result.code === 200) {
      let fee_request = result.fee_request;
      let guardian_name = "";
      if (selectedUser?.name) {
        let guardian_email = selectedUser.email;
        guardian_name = selectedUser.name + " (" + guardian_email + ")";
      }
      fee_request.guardian_name = guardian_name;
      fee_request.table_avatar = {
        src: s3baseUrl + fee_request.receipt_slip,
        alt: "Receipt Slip",
      };
      if (formType === "ADD") {
        setFeesList((old) => [fee_request, ...old]);
      } else {
        setFeesList((old) =>
          old.map((obj) => {
            if (obj._id === state._id) {
              return fee_request;
            }
            return obj;
          })
        );
      }

      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_clients_list = async () => {
    const result = await clients_list_with_students_api();
    if (result.code === 200) {
      setClientsList(result.clients);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  const handleChangeClient = (value) => {
    if (value?.students?.length > 0) {
      setSelectedStudents(value.students);
      setState((old) => ({ ...old, currency: value.currency }));
    } else {
      setSelectedStudents([]);
      setState((old) => ({ ...old, currency: "usd" }));
    }
    setSelectedUser(value);
  };

  useEffect(() => {
    get_clients_list();
    if (formType === "EDIT") {
      setState(selectedObject);
      setSelectedUser(selectedObject.created_for);
      setSelectedStudents(selectedObject.students);
    }
  }, [formType, selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6 mt-4">
              <MUIAutocomplete
                inputLabel="Choose Guardian"
                selectedOption={selectedUser}
                setSelectedOption={handleChangeClient}
                optionsList={clientsList}
                autoComplete="off"
                name="name"
                required={true}
                disabled={Boolean(formType === "EDIT")}
              />
            </div>

            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="fee_status">Fee Status</InputLabel>
                <Select
                  labelId="fee_status"
                  id="fee_status"
                  label="Fee Status"
                  name="fee_status"
                  className="mui-select"
                  value={state.fee_status}
                  onChange={handleChange}
                >
                  {FEES_STATUSES.map((fee_status) => {
                    return (
                      <MenuItem value={fee_status}>
                        {show_proper_words(fee_status)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 ">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="month">Month</InputLabel>
                <Select
                  labelId="month"
                  id="month"
                  label="Month"
                  name="month"
                  className="mui-select"
                  value={state.month}
                  onChange={handleChange}
                >
                  {MONTHS_ARRAY.map((month) => {
                    return (
                      <MenuItem value={month.value}>{month.name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 ">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="year">Year</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  label="Year"
                  name="year"
                  className="mui-select"
                  value={state.year}
                  onChange={handleChange}
                >
                  {years.map((year) => {
                    return <MenuItem value={year}>{year}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          {selectedStudents?.length > 0 && (
            <div className="col-12 mt-4">
              <StudentsTable
                students={selectedStudents}
                currency={state.currency}
                total_amount={total_amount}
                payment_charges={payment_charges}
              />
            </div>
          )}
          <div className="col-12 add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
